import { Component, ComponentRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  IAction,
  ImporterColumnDefinition,
  ImporterSheetColumn,
  ImporterValidators,
  MessageKeys,
} from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-importer-column-bind-popup',
  templateUrl: './importer-column-bind-popup.component.html',
  styleUrls: ['./importer-column-bind-popup.component.scss'],
})
export class ImporterColumnBindPopupComponent implements OnInit {
  component: ComponentRef<any>;
  colDefinitions: ImporterColumnDefinition[] = [];
  sheetColumns: ImporterSheetColumn[] = [];
  draggedElement: ImporterColumnDefinition;
  draggedElementIndex: number;
  sheetData: any[];
  delimiterTypeControl: FormControl = new FormControl(',', Validators.required);
  importerValidators = ImporterValidators;
  @Input() submitButtonAction: IAction = {
    id: 1,
    label: 'Ok',
    buttonType: 'button',
    command: this.onSubmit.bind(this),
    icon: 'pi pi-check',
  };
  @Input() cancelButtonAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.cancelChanges.bind(this),
    icon: 'pi pi-times',
  };
  resetButtonAction: IAction = {
    id: 3,
    label: 'Clear All',
    buttonType: 'button',
    command: this.resetAllBindings.bind(this),
    icon: 'pi pi-times',
    buttonStyle: 'outlined',
    color: 'secondary',
  };
  autoBindAction: IAction = {
    label: 'Auto Bind',
    id: 4,
    color: 'secondary',
    icon: 'pi pi-link',
    iconPos: 'left',
    command: this.autoBind.bind(this),
    tooltipText: 'Auto Binds First Row and removes it.',
    tooltipPosition: 'top',
  };
  commaModeOptions = [
    { label: 'Comma', value: true, icon: 'pi pi-book', tooltip: 'Values Separated by Commas' },
    {
      label: 'Columns',
      value: false,
      icon: 'pi pi-exclamation-triangle',
      tooltip: 'Each Bound Column is a Value in the array',
    },
  ];
  constructor(
    public viewContainerRef: ViewContainerRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toast: ToastService
  ) {
    this.colDefinitions = [...this.config?.data?.colDefinitions];
    this.sheetColumns = [...this.config?.data?.sheetColumns];
    this.sheetData = this.config?.data?.sheetData;
    this.sheetColumns.forEach((element) => {
      if (element.boundDefinition)
        element.validAmount = this.calcValidationAmount(element.colName, element.boundDefinition);
    });
  }

  ngOnInit(): void {}
  resetAllBindings() {
    this.colDefinitions = this.colDefinitions.map((x) => {
      return {
        ...x,
        boundedSheetColumns: [],
      };
    });
    this.sheetColumns = this.sheetColumns.map((x) => {
      return {
        ...x,
        boundDefinition: null,
      };
    });
  }
  onSubmit(data: any) {
    this.config.data.colDefinitions = this.colDefinitions;
    this.config.data.sheetColumns = this.sheetColumns;
    this.toast.success(MessageKeys.success, MessageKeys.columnsAssigned);
    this.ref.close({ colDefinitions: this.colDefinitions, sheetColumns: this.sheetColumns });
  }
  dragStart(event, element: any) {
    this.draggedElement = element;
    this.draggedElementIndex = element;
  }

  drop(event, item, index) {
    // if (this.draggedElement) {

    //     this.sheetColumns[index].boundDefinition = this.draggedElement;
    //     this.sheetColumns[index].validAmount = this.calcValidationAmount(this.sheetColumns[index].colName,this.sheetColumns[index].boundDefinition);
    //     this.sheetColumns = [...this.sheetColumns];
    //     // let draggedElementIndex = this.findIndex(this.draggedElement);
    //     // this.selectedElements = [...this.selectedElements, this.draggedElement];
    //     // this.availableElements = this.availableElements.filter((val, i) => i != draggedElementIndex);
    //     // this.draggedElement = null;
    //     // this.initLuckySheet();
    // }
    if (item) {
      this.sheetColumns[this.draggedElementIndex].boundDefinition = { ...item };
      this.sheetColumns[this.draggedElementIndex].validAmount = this.calcValidationAmount(
        this.sheetColumns[this.draggedElementIndex].colName,
        this.sheetColumns[this.draggedElementIndex].boundDefinition
      );
      this.sheetColumns = [...this.sheetColumns];
      if (!this.colDefinitions?.[index]?.boundedSheetColumns) this.colDefinitions[index].boundedSheetColumns = [];
      if (
        this.colDefinitions?.[index].fieldGroupKey ||
        (this.colDefinitions?.[index]?.isArray && !!!this.colDefinitions?.[index]?.isCommaDelimiter)
      ) {
        if (
          this.colDefinitions?.[index]?.boundedSheetColumns.findIndex(
            (x) => x.id == this.sheetColumns[this.draggedElementIndex].id
          ) == -1
        ) {
          this.colDefinitions?.[index]?.boundedSheetColumns?.push(this.sheetColumns[this.draggedElementIndex]);
        }
      } else {
        this.colDefinitions[index].boundedSheetColumns.forEach((boundedItem) => {
          boundedItem.boundDefinition = null;
        });
        this.colDefinitions[index].boundedSheetColumns = [this.sheetColumns[this.draggedElementIndex]];
      }

      this.sheetColumns.forEach((element) => {
        if (element.boundDefinition)
          element.validAmount = this.calcValidationAmount(element.colName, element.boundDefinition);
      });
      // let draggedElementIndex = this.findIndex(this.draggedElement);
      // this.selectedElements = [...this.selectedElements, this.draggedElement];
      // this.availableElements = this.availableElements.filter((val, i) => i != draggedElementIndex);
      // this.draggedElement = null;
      // this.initLuckySheet();
    }
  }

  dragEnd(event) {
    this.draggedElement = null;
  }
  onRemoveBinding(index) {
    this.sheetColumns[index].boundDefinition = null;
    this.sheetColumns = [...this.sheetColumns];
  }
  onRemoveBindingFromField(index, index2) {
    let sheetColIndex = this.sheetColumns.findIndex(
      (x) => x.colName == this.colDefinitions?.[index]?.boundedSheetColumns[index2]?.colName
    );
    this.sheetColumns[sheetColIndex].boundDefinition = null;
    this.sheetColumns = [...this.sheetColumns];
    this.colDefinitions?.[index]?.boundedSheetColumns.splice(index2, 1);
  }
  cancelChanges() {
    this.ref.close(false);
  }
  calcValidationAmount(key, colDefinition) {
    let validAmount = 0;
    for (let i = 0; i < this.sheetData.length; i++) {
      const element = this.sheetData[i];
      if (this.isValid(element[key], colDefinition)) validAmount++;
    }
    return (validAmount * 100) / this.sheetData.length;
  }
  isValid(item, colDefinition: ImporterColumnDefinition) {
    //@TODO: duplicate validation cause there's no other way around it
    if (colDefinition.field.required) {
      return !!item?.v;
    } else if (!colDefinition.isArray) {
      colDefinition?.field?.validators?.forEach((validator) => {
        if (validator.key == 'dropdown') {
          return (validator?.value?.value1 as string)?.includes(item[colDefinition?.field?.key]);
        } else if (validator.key == 'text_content') {
          return (validator?.value?.value1 as string)?.startsWith(item[colDefinition?.field?.key]);
        }
      });
    }
    return true;
  }
  autoBind() {
    const _this = this;
    _this.sheetColumns.forEach((sheetCol) => {
      sheetCol.boundDefinition = null;
      sheetCol.validAmount = 0;
      let sheetDataCell = this.sheetData?.[0]?.[sheetCol.colName];
      if (sheetDataCell && sheetDataCell?.v) {
        let colDefIndex = _this.colDefinitions.findIndex(
          (x) =>
            x?.field?.key?.toLowerCase() == sheetDataCell?.v?.toLowerCase() ||
            x?.field?.name?.toLowerCase() == sheetDataCell?.v?.toLowerCase()
        );
        let colDef = colDefIndex != -1 ? _this.colDefinitions[colDefIndex] : null;
        if (colDef) {
          if (!_this.colDefinitions[colDefIndex].boundedSheetColumns)
            _this.colDefinitions[colDefIndex].boundedSheetColumns = [];
          if (_this.colDefinitions[colDefIndex].fieldGroupKey) {
            if (_this.colDefinitions[colDefIndex].boundedSheetColumns.findIndex((x) => x.id == sheetCol.id) == -1) {
              _this.colDefinitions[colDefIndex].boundedSheetColumns.push(sheetCol);
            }
          } else {
            _this.colDefinitions[colDefIndex].boundedSheetColumns = [sheetCol];
          }
          sheetCol.boundDefinition = _this.colDefinitions[colDefIndex];
          // sheetCol.validAmount = _this.calcValidationAmount(sheetCol.colName,colDef,sheetData);
        }
      }
    });
    _this.sheetColumns.forEach((element) => {
      if (element.boundDefinition)
        element.validAmount = _this.calcValidationAmount(element.colName, element.boundDefinition);
    });
  }
  onDelimiterChange(event, item, index) {
    if (event) {
      this.colDefinitions?.[index]?.boundedSheetColumns?.forEach((sheetCol) => {
        sheetCol.boundDefinition = null;
      });
      this.colDefinitions[index].boundedSheetColumns = [];
    } else {
      this.colDefinitions?.[index]?.boundedSheetColumns?.forEach((sheetCol) => {
        sheetCol.boundDefinition.isCommaDelimiter = event;
      });
    }
  }
}
