<div class="flex flex-row gap-4 mt-3 w-full mb-2">
  <div class="flex flex-wrap w-3 align-items-start gap-3 h-fit">
    <!-- <ng-container *ngFor="let item of colDefinitions; let i=index">
    <div class="flex gap-2 justify-content-center align-items-center">
      <p-chip pDraggable="importCols" (onDragStart)="dragStart($event, item)" (onDragEnd)="dragEnd($event)">
        <span *ngIf="item?.field?.required" class="fa fa-asterisk p-chip-icon text-red-500"></span>
        <div class="p-chip-text">{{item?.field?.name}}</div>
      </p-chip>
      <app-importer-column-definition-overlay [columnDefinition]="item"></app-importer-column-definition-overlay>
    </div>
  </ng-container> -->
    @for (item of sheetColumns; track item; let i = $index) {
      @if (!item.boundDefinition) {
        <li
          class="flex flex-column border-1 surface-border border-round surface-card shadow-1 text-center align-items-center relative"
          style="padding: 0.75rem"
          pDraggable="importCols"
          (onDragStart)="dragStart($event, i)"
          (onDragEnd)="dragEnd($event)"
        >
          <i class="pi pi-arrows-alt text-600 absolute" style="top: 2px; right: 2px; font-size: 0.6rem"></i>
          <div class="">
            <span class="inline-block text-900 font-medium mr-2 mb-1 md:mb-0">
              {{ item.colName }}
              <small class="text-400">{{ this.sheetData?.[0]?.[item.colName]?.v }}</small>
            </span>
            <!-- <span *ngIf="item.boundDefinition"
          class="text-600">
          <p-chip [removable]="true"
            (onRemove)="onRemoveBinding(i)">
            <span *ngIf="item?.boundDefinition?.field?.required"
            class="fa fa-asterisk p-chip-icon text-red-500"></span>
            <div class="p-chip-text">{{item?.boundDefinition?.field?.name}}</div>
          </p-chip>
        </span> -->
          </div>
          <!-- <div *ngIf="item.boundDefinition"
      class="mt-2 md:mt-0 ml-0 md:ml-4 flex align-items-center">
      <div class="surface-300 border-round overflow-hidden"
        style="height: 8px; width: 140px">
        <div [class]="'bg-'+(item.validAmount | getPercentageColor)+'-500 h-full'"
        [style]="'width: '+item.validAmount +'%'"></div>
      </div>
      <span [class]="'text-'+(item.validAmount | getPercentageColor)+'-500 ml-3 font-medium'"
        style="min-width: 40px;">
        %{{item.validAmount | parseToFixedNumber : 'int'}}
      </span>
    </div> -->
        </li>
      }
    }
  </div>
  <!-- <div class="col-12 md:col-6 drop-column h-6rem" >
</div> -->
  <div class="flex flex-column w-9 overflow-y-auto h-30rem max-h-30rem">
    <div class="">
      <!-- <div class="flex justify-content-between align-items-center mb-5">
    <span class="text-xl text-900 font-medium">Best Selling Products</span>
  </div> -->
      <ul class="list-none p-0 m-0">
        @for (item of colDefinitions; track item; let i = $index) {
          @if (!!!item?.field?.hideInImporter) {
            <li
              pDroppable="importCols"
              (onDrop)="drop($event, item, i)"
              class="flex flex-column gap-2 mb-3 border-round p-2 drop-column w-full"
            >
              <div class="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-900 font-medium text-xl mb-2">
                      {{ item?.field?.name }} <small class="text-400">{{ item?.field?.key }}</small>
                      @if (item?.field?.required) {
                        <i
                          [pTooltip]="
                            importerValidators['required']?.name + ': ' + importerValidators['required']?.description
                          "
                          [class]="importerValidators['required']?.icon + ' text-red-500 mr-2'"
                        ></i>
                      }
                      @for (val of item?.field?.validators; track val) {
                        @if (importerValidators[val?.key]) {
                          <i
                            [pTooltip]="
                              importerValidators[val?.key]?.name + ': ' + importerValidators[val?.key]?.description
                            "
                            [class]="importerValidators[val?.key]?.icon + ' text-900 mr-2'"
                          ></i>
                        }
                      }
                    </div>
                    <div class="flex align-items-center">
                      <!-- <i class="pi pi-apple text-600 mr-2"></i> -->
                      <span class="text-600">{{ item?.field?.description }}</span>
                      <!-- <span class="inline-flex p-1 bg-green-100 text-green-600 font-medium text-sm border-round ml-3">$12.24</span> -->
                    </div>
                  </div>
                </div>
                <div class="sm:text-right mt-3 md:mt-0">
                  <!-- progress is here  -->
                  @if (item?.boundedSheetColumns?.length > 0) {
                    <div class="flex align-items-center justify-content-end">
                      <div class="surface-300 border-round overflow-hidden" style="height: 8px; width: 140px">
                        <div
                          [class]="
                            'bg-' + (item?.boundedSheetColumns[0]?.validAmount | getPercentageColor) + '-500 h-full'
                          "
                          [style]="'width: ' + item?.boundedSheetColumns[0]?.validAmount + '%'"
                        ></div>
                      </div>
                      <span
                        [class]="
                          'text-' +
                          (item?.boundedSheetColumns[0]?.validAmount | getPercentageColor) +
                          '-500 ml-3 font-medium'
                        "
                        style="min-width: 40px"
                      >
                        %{{ item?.boundedSheetColumns[0]?.validAmount | parseToFixedNumber: 'int' }}
                      </span>
                    </div>
                  }
                  <!-- <div class="text-sm text-600 mt-2">10K Reviews</div> -->
                  <div class="flex flex-row gap-2">
                    @for (val of item?.boundedSheetColumns; track val; let i2 = $index) {
                      @if (val) {
                        <span class="text-600">
                          <p-chip [removable]="true" (onRemove)="onRemoveBindingFromField(i, i2)">
                            <div class="p-chip-text">{{ val?.colName }}</div>
                          </p-chip>
                        </span>
                      }
                    }
                  </div>
                </div>
              </div>
              <div class="flex flex-row justify-content-between gap-3 w-full">
                @if (item.isArray) {
                  <p-selectButton
                    [options]="commaModeOptions"
                    [(ngModel)]="item.isCommaDelimiter"
                    optionLabel="label"
                    optionValue="value"
                    class="w-4"
                    styleClass="view-switch-buttons"
                  >
                    <ng-template let-buttonItem>
                      <div
                        class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                        [pTooltip]="buttonItem.tooltip"
                        tooltipPosition="top"
                        style="padding: 0.629rem 0"
                      >
                        <i [class]="buttonItem.icon"></i>
                        @if (buttonItem.label) {
                          <div>
                            {{ buttonItem.label }}
                          </div>
                        }
                      </div>
                    </ng-template>
                  </p-selectButton>
                }
                <!-- <div *ngIf="item.isArray" class="flex flex-row gap-2 text-sm text-400">
            <p-inputSwitch [(ngModel)]="item.isCommaDelimiter"
              (ngModelChange)="onDelimiterChange($event,item,i)"
            ></p-inputSwitch>
            <label>{{'Comma Delimiter'}}</label>
          </div> -->
                <div class="grid p-fluid w-full">
                  <div class="col-12">
                    @if (item.isArray && item.isCommaDelimiter) {
                      <app-suggestion-input
                        [label]="'Delimiter Type'"
                        [placeholder]="'Ex: Person1,Person2 (Default is comma ,)'"
                        [control]="delimiterTypeControl"
                        [viewMode]="'edit'"
                        [items]="[
                          { label: 'Comma: ,', value: ',' },
                          { label: 'Colon: :', value: ':' },
                          { label: 'Semi-colon: ;', value: ';' },
                          { label: 'Dot: .', value: '.' },
                        ]"
                        [type]="'text'"
                        (onChanges)="item.delimiterType = $event"
                      >
                      </app-suggestion-input>
                    }
                  </div>
                </div>
              </div>
            </li>
          }
          <!-- <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
    pDroppable="importCols"
    (onDrop)="drop($event, item)">
    <div class="flex gap-2 justify-content-center align-items-center">
      <p-chip>
        <span *ngIf="item?.field?.required"
        class="fa fa-asterisk p-chip-icon text-red-500"></span>
        <div class="p-chip-text">{{item?.field?.name}}</div>
      </p-chip>
      <app-importer-column-definition-overlay
      [columnDefinition]="item"></app-importer-column-definition-overlay>
    </div>
  </li> -->
        }
        <!-- <ng-container *ngFor="let item of sheetColumns; let i = index">
<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
  pDroppable="importCols" (onDrop)="drop($event, i)">
  <div class="mr-2">
    <span class="inline-block text-900 font-medium mr-2 mb-1 md:mb-0">
      {{item.colName}}
    </span>
    <span *ngIf="item.boundDefinition" class="text-600">
      <p-chip [removable]="true" (onRemove)="onRemoveBinding(i)">
        <span *ngIf="item?.boundDefinition?.field?.required"
        class="fa fa-asterisk p-chip-icon text-red-500"></span>
        <div class="p-chip-text">{{item?.boundDefinition?.field?.name}}</div>
      </p-chip>
    </span>
  </div>
  <div *ngIf="item.boundDefinition" class="mt-2 md:mt-0 ml-0 md:ml-4 flex align-items-center">
    <div class="surface-300 border-round overflow-hidden" style="height: 8px; width: 140px">
      <div [class]="'bg-'+(item.validAmount | getPercentageColor)+'-500 h-full'"
      [style]="'width: '+item.validAmount +'%'"></div>
    </div>
    <span [class]="'text-'+(item.validAmount | getPercentageColor)+'-500 ml-3 font-medium'"
      style="min-width: 40px;">
      %{{item.validAmount | parseToFixedNumber : 'int'}}
    </span>
  </div>
</li>
</ng-container> -->
      </ul>
    </div>
    <!-- <div #container style="width: 100%"></div> -->
  </div>
</div>
<div class="flex flex-row-reverse w-full gap-2">
  <app-button [action]="submitButtonAction"></app-button>
  <app-button [action]="resetButtonAction"></app-button>
  <app-button [action]="autoBindAction"></app-button>
</div>
