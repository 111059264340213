import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPercentageColor',
})
export class GetPercentageColorPipe implements PipeTransform {
  transform(value: any, reverse: boolean = false, args?: any): string {
    if (typeof value === 'number') {
      if (value < 31) return ColorMap.red;
      else if (value < 71) return ColorMap.orange;
      else if (value < 100) return ColorMap.yellow;
      else return ColorMap.green;
    } else return ColorMap.red;
  }
}
export enum ColorMap {
  red = 'red',
  orange = 'orange',
  yellow = 'yellow',
  green = 'green',
}
